<div id="strength">
  <small>strength:</small>
  <ul id="strengthBar">
    <li class="point" [style.background-color]="bars[0]"></li>
    <li class="point" [style.background-color]="bars[1]"></li>
    <li class="point" [style.background-color]="bars[2]"></li>
    <li class="point" [style.background-color]="bars[3]"></li>
    <li class="point" [style.background-color]="bars[4]"></li>
  </ul>
</div>
