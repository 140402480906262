<span class="part-badge"
  [style.background-color]="color"
  [style.color]="contrastColor"
  *ngIf="badgeType !== 2"
>
  {{ typeName }}
</span>
<span class="role-badge" *ngIf="badgeType !== 1 && roleName">
  {{ roleName }}
</span>
