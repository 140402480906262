import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AuthJwtService, User } from '@myrmidon/auth-jwt-login';

@Component({
  selector: 'auth-jwt-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.css'],
})
export class UserEditorComponent implements OnInit {
  private _user: User | undefined;

  public email: UntypedFormControl;
  public emailConfirmed: UntypedFormControl;
  public lockoutEnabled: UntypedFormControl;
  public firstName: UntypedFormControl;
  public lastName: UntypedFormControl;
  public roles: UntypedFormControl;
  public form: UntypedFormGroup;

  public unlocked: boolean | undefined;

  @Input() public set user(value: User | undefined) {
    this._user = value;
    this.updateForm(value);
  }
  public get user(): User | undefined {
    return this._user;
  }

  @Output() userChange: EventEmitter<User>;
  @Output() editorClose: EventEmitter<any>;

  constructor(formBuilder: UntypedFormBuilder, private _authService: AuthJwtService) {
    // events
    this.userChange = new EventEmitter<User>();
    this.editorClose = new EventEmitter<any>();
    // form
    this.email = formBuilder.control(null, [
      Validators.required,
      Validators.email,
    ]);
    this.emailConfirmed = formBuilder.control(false);
    this.lockoutEnabled = formBuilder.control(false);
    this.firstName = formBuilder.control(null, [
      Validators.required,
      Validators.maxLength(50),
    ]);
    this.lastName = formBuilder.control(null, [
      Validators.required,
      Validators.maxLength(50),
    ]);
    this.roles = formBuilder.control(null, Validators.maxLength(200));

    this.form = formBuilder.group({
      email: this.email,
      emailConfirmed: this.emailConfirmed,
      lockoutEnabled: this.lockoutEnabled,
      firstName: this.firstName,
      lastName: this.lastName,
      roles: this.roles,
    });
  }

  ngOnInit(): void {}

  private updateForm(user: User | undefined): void {
    if (!user) {
      this.form.reset();
    } else {
      this.email.setValue(user.email);
      this.emailConfirmed.setValue(user.emailConfirmed);
      this.lockoutEnabled.setValue(user.lockoutEnabled);
      this.firstName.setValue(user.firstName);
      this.lastName.setValue(user.lastName);
      if (user.roles?.length > 0) {
        this.roles.setValue(user.roles.join(' '));
      } else {
        this.roles.setValue(null);
      }
    }
  }

  private getUserFromForm(): User {
    return {
      userName: this._user?.userName || '',
      email: this.email.value,
      emailConfirmed: this.emailConfirmed.value,
      lockoutEnabled: this.lockoutEnabled.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      roles: this.roles.value
        ? this.roles.value.split(' ').filter((s: string) => s)
        : [],
    };
  }

  public endLockout(): void {
    if (this.unlocked) {
      return;
    }
    this._user!.lockoutEnd = this._authService.getUTCDate();
    this.unlocked = true;
  }

  public close(): void {
    this.editorClose.emit();
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }
    this.userChange.emit(this.getUserFromForm());
  }
}
