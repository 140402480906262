<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content>
  {{ prompt }}
</mat-dialog-content>
<mat-dialog-actions>
  <button
    type="button"
    mat-raised-button
    color="warn"
    (click)="dialogRef.close(true)"
  >
    {{ ok }}
  </button>
  <button type="button" mat-button (click)="dialogRef.close()">
    {{ cancel }}
  </button>
</mat-dialog-actions>
