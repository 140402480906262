import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

export interface Credentials {
  name: string;
  password: string;
  returnUrl?: string;
}

@Component({
  selector: 'auth-jwt-login',
  templateUrl: './auth-jwt-login.component.html',
  styleUrls: ['./auth-jwt-login.component.css'],
})
export class AuthJwtLoginComponent {
  private _returnUrl: string;

  @Input()
  public forgot: boolean | undefined;

  @Input()
  public set validating(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  public errorMessage: string | undefined;

  @Output()
  public loginRequest: EventEmitter<Credentials>;

  @Output()
  public resetRequest: EventEmitter<any>;

  public name: UntypedFormControl;
  public password: UntypedFormControl;
  public form: UntypedFormGroup;
  public hide: boolean;

  constructor(formBuilder: UntypedFormBuilder, route: ActivatedRoute) {
    this.hide = true;
    // events
    this.loginRequest = new EventEmitter<Credentials>();
    this.resetRequest = new EventEmitter<any>();
    // form
    this.name = formBuilder.control(null, Validators.required);
    this.password = formBuilder.control(null, Validators.required);
    this.form = formBuilder.group({
      name: this.name,
      password: this.password,
    });

    // get return URL if any
    this._returnUrl = route.snapshot.queryParams['returnUrl'];
  }

  requestLogin() {
    if (this.form.invalid) {
      return;
    }
    this.loginRequest.emit({
      name: this.name.value,
      password: this.password.value,
      returnUrl: this._returnUrl,
    });
  }

  public requestReset(): void {
    this.resetRequest.emit();
  }
}
